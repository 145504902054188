import React from 'react'
// import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

import {
  StyledEngineProvider,
  Card, CardMedia, CardContent, Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  homeCard: {
    backgroundColor: alpha(theme.palette.background.paper, 0.85),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderWidth: `3px 1px 2px`,
    overflow: 'inherit',

    '&>a': {
      textDecoration: 'none'
    },
  },

  homeCardLink: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[8],
    },    
  },

  cardMedia: {
    margin: theme.spacing(-8,2,0),
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '36%',
    backgroundSize: 'contain',
    '&>div': {
      marginTop: `calc(-50% - ${theme.spacing(3.75)})`,
      fontSize: theme.typography.h4.fontSize,
      // [theme.breakpoints.only('md')]: {
      //   marginTop: `calc(-50% - ${theme.spacing(3)})`,
      //   fontSize: theme.typography.h5.fontSize,
      // },
    },
  },
  cardContent: {
    '&, &:last-child': { padding: theme.spacing(1, 2) },
  },

  homeCardTooltip: {},
}))

const HomeCardContent = ({ dataSource, imageFolder }) => {
  const classes = useStyles()
  return (
    <StyledEngineProvider injectFirst>
        {dataSource.image ? (
          <CardMedia
            image={`/images/${imageFolder}/${dataSource.image}`}
            className={classes.cardMedia}
          />
        ) : (
          <CardMedia className={classes.cardMedia}>
            <Typography variant="button" component="div">
              {dataSource.organisation && dataSource.organisation}
            </Typography>
          </CardMedia>
        )}
        {dataSource.cardDescription &&
          <CardContent>
            <Typography color="textSecondary" component="div">
              <ReactMarkdown children={dataSource.cardDescription} />
            </Typography>
          </CardContent>
        }
    </StyledEngineProvider>
  )
}

const homeCard = ({ dataSource, imageFolder }) => {
  const classes = useStyles()
  // console.log(dataSource)
  return (
    <StyledEngineProvider injectFirst>
      <Card elevation={3} className={clsx(classes.homeCard, dataSource.cardURL && classes.homeCardLink)}>
        {dataSource.cardURL
          ? (
            <NavLink to={dataSource.cardURL}>
              <HomeCardContent dataSource={dataSource} imageFolder={imageFolder} />
            </NavLink>
          )
          : <HomeCardContent dataSource={dataSource} imageFolder={imageFolder} />
        }
      </Card>
    </StyledEngineProvider>
  )
}

homeCard.propTypes = {}

export default homeCard
