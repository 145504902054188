import React from 'react'
import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown'

import moment from 'moment';
import 'moment/locale/ro'

import clsx from 'clsx'
import {
  StyledEngineProvider,
  alpha,
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  // Button,
  Chip,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import OpenModalIcon from '@material-ui/icons/Launch'

import BlogDetails from '../comps/BlogDetails'
import StatusAvatar from '../comps/StatusAvatar'

// import "./styles.css";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(theme.shape.verticalSpacer),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-4),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-4.5),
    },
  },
  heroImage: {
    maxWidth: '100%',
    margin: '0 auto',
    // borderRadius: theme.shape.borderRadius
  },

  domainTitle: {
    margin: theme.spacing(6, 0, 0),

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  firstDomainTitle: {
    margin: theme.spacing(0),
  },

  titleContainer: {
    marginBottom: theme.spacing(theme.shape.verticalSpacer),
  },

  title: {
    fontSize: 14,
  },
  titleLabel: {
    marginRight: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2.5)}`,
    verticalAlign: 'middle',
  },

  card: {
    position: 'relative',
    // marginBottom: theme.spacing(1),
    padding: 0,
    // backgroundColor: 'rgba(255, 255, 255, 0.65)',
    // border: "1px solid #ccc",
    borderRadius: 0,

    // [theme.breakpoints.down('sm')]: {
    //   backgroundColor: 'rgba(255, 255, 255, 1)',
    // },

    '&.hasLink': {
      // backgroundColor: 'rgba(255, 255, 255, 0.85)',
      backgroundColor: 'transparent',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    },
    '&.hasLink:hover': {
      // backgroundColor: 'white',
      backgroundColor: 'transparent',
    },
    // '&.hasLink,&.hasLink *': {
    //   cursor: 'pointer',
    // },
    '& *': {
      cursor: 'default',
    },
    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: 'Crimson',
      color: 'white',
      opacity: 0.65,
    },
  },
  cardTitle: {
    marginTop: 0,
    color: theme.palette.primary.main,
    '&>p': {
      margin: 0,
    },
  },
  cardImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 0,
    paddingTop: '100%',
    borderRadius: '50%',
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    width: 'auto',
    height: '100%',
  },
  contentImage: {
    maxWidth: '100%',
    margin: theme.spacing(2, 0),
  },
  cardContent: {
    padding: 0,
  },

  extraData: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  actionButton: {
    boxShadow: 'none',
  },

  section: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2, 1.5),
    border: `1px solid ${alpha(theme.palette.secondary.light, 0.47)}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionListFirst: {
    padding: theme.spacing(1.5, 0),
    borderWidth: '1px 0',
    borderRadius: 0,
  },
  sectionList: {
    padding: theme.spacing(0, 0, 1.5, 0),
    borderWidth: '0 0 1px',
    borderRadius: 0,
  },
  sectionListTop: {
    padding: theme.spacing(1.5, 0, 0),
    borderWidth: '1px 0 0',
    borderRadius: 0,
  },

  chip: {
    margin: theme.spacing(0, 0.25, 0.5),
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  chipDomenii: {
    margin: theme.spacing(0.25),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    // color: theme.palette.secondary.contrastText,
    color: 'white',
    // textTransform: "capitalize",

    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.dark
    // }
  },

  blogHero: {
    width: '100%',
  },
  blogListDate: {
    color: 'white',
    textAlign: 'center',

    '&.MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
    },

    '& .MuiCardContent-root': {
      padding: 8
    }

  },
}))

function BlogList({ config, dataSource }) {
  // console.log(props);
  const classes = useStyles()

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      // Add object to list for given key's value
      acc[key].push(obj)
      return acc
    }, {})
  }

  // const data = groupBy(dataSource, 'domain')
  // console.log(config.showTotalRegistered)

  return (
    <StyledEngineProvider injectFirst>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            component="h1"
          >
            Blog
          </Typography>
        </Grid>
        {dataSource.map((item, index) => {
            // console.log(data[item])
          if (JSON.parse(item.display)) {
            return (
              <React.Fragment key={item.id}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Card
                    elevation={0}
                    className={clsx(classes.card, 'hasLink')}
                  >
                    <CardContent className={classes.cardContent}>
                      <Grid container spacing={3}>
                        {item.datePublished && (
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={1}
                            className={classes.extraData}
                          >
                            <Card className={classes.blogListDate}>
                              <CardContent>
                                <Typography
                                  variant="h4"
                                  component="div"
                                  style={{ margin: 0 }}
                                >
                                  {moment(item.datePublished).format('D')}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  component="div"
                                  style={{ margin: 0 }}
                                >
                                  {moment(item.datePublished).format('MMM')}
                                  <br />
                                  {moment(item.datePublished).format('YYYY')}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={item.datePublished ? 10 : 12}
                          md={item.datePublished ? 11 : 12}
                          className={classes.extraData}
                        >
                          <Typography
                            variant="h4"
                            component="h2"
                            gutterBottom
                            className={classes.cardTitle}
                          >
                            <ReactMarkdown children={item.title} />
                          </Typography>
                        </Grid>
                        {item.hero && (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className={classes.extraData}
                          >
                            <img
                              className={classes.blogHero}
                              src={`//noapteacercetatorilor.educatiepentrustiinta.ro/blog/images/${item.hero}`}
                              alt={item.title}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          md={item.hero ? 6 : 12}
                          className={classes.extraData}
                        >
                          {item.intro && (
                            <Typography
                              variant="body1"
                              component="div"
                              gutterBottom
                            >
                              <ReactMarkdown children={item.intro} />
                            </Typography>
                          )}
                          <BlogDetails
                            dialogData={item}
                            buttonColor="primary"
                            buttonVariant="contained"
                            buttonSize="large"
                            buttonText="Citește"
                            buttonEndIcon={<OpenModalIcon />}
                            classes={classes}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </React.Fragment>
            );
          }
          return null
          })}
      </Grid>
    </StyledEngineProvider>
  )
}

BlogList.propTypes = {
  db: PropTypes.shape({
    sheet1: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default BlogList
