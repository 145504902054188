import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  StyledEngineProvider,
  alpha,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  // InputBase,
  // Badge,
  MenuItem,
  Menu,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import MenuIcon from '@material-ui/icons/Menu'
// import SearchIcon from '@material-ui/icons/Search'
// import AccountCircle from '@material-ui/icons/AccountCircle'
// import MailIcon from '@material-ui/icons/Mail'
// import NotificationsIcon from '@material-ui/icons/Notifications'
// import MoreIcon from '@material-ui/icons/MoreVert'

// const getData = ({ data }) => {
// console.log(data)
//   return data
// }

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  siteTitle: {
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  title: {
    // display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block'
    // }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  mainMenu: {
    marginRight: theme.spacing(2),

    '& .MuiButton-root': {
      color: theme.palette.common.white,
    },
  },
  mobileMenu: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-1),
    },
  },
  menuItemMobile: {
    padding: theme.spacing(2.5, 4),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2.25, 4),
    },
  },
  currentPage: {
    '&.MuiButton-root': {
      color: theme.palette.secondary.main,
    },
  },
  currentPageMobile: {
    '&, &:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },

  siteLogo: {
    maxHeight: theme.spacing(6),
    marginRight: theme.spacing(2),
  },
}))

const Matches = (breakpoint, to) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints[to](breakpoint))
}

const MainNav = ({ dataSource, archive, config, position, siteLanguage }) => {
  const classes = useStyles()

  const data = dataSource
  // console.log('siteLanguage mainNav: ', siteLanguage)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  // const handleProfileMenuOpen = event => {
  //   setAnchorEl(event.currentTarget)
  // }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const [archAnchorEl, setArchAnchorEl] = React.useState(null)
  const open = Boolean(archAnchorEl)
    const handleClick = (event) => {
      setArchAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setArchAnchorEl(null)
    }


  const menuId = 'primary-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{ paper: classes.mobileMenu }}
    >
      {data && (
        <div>
          {data.map((menu) => {
            // console.log(menu)
            if (JSON.parse(menu.display)) {
              if (JSON.parse(menu.addRoute)) {
                return (
                  <MenuItem
                    className={classes.menuItemMobile}
                    to={menu.route}
                    exact
                    component={NavLink}
                    activeClassName={classes.currentPageMobile}
                    key={menu.id}
                    onClick={() => handleMobileMenuClose()}
                  >
                    {menu.itemTitle}
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem
                    {...JSON.parse(menu.contentSource)}
                    className={classes.menuItemMobile}
                    key={menu.id}
                    onClick={() => handleMobileMenuClose()}
                  >
                    {menu.itemTitle}
                  </MenuItem>
                )
              }
            }
            return null
          })}
        </div>
      )}
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  )

  // console.log(matches('md', 'down'))

  return (
    <StyledEngineProvider injectFirst>
      {/* <AppBar position={Matches('md', 'down') ? 'fixed' : 'sticky'}> */}
      <AppBar position="fixed">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Button component={NavLink} to="/">
            {config.siteLogo && (
              <img
                src={config.siteLogo}
                alt={[config.siteTitle, config.siteDescription].join(', ')}
                className={classes.siteLogo}
              />
            )}
            <Typography
              className={classes.siteTitle}
              variant="h6"
              noWrap
              title={[config.siteShortTitle, config.siteTitle].join(' : ')}
            >
              {Matches('md', 'down')
                ? config.siteShortTitle
                  ? config.siteShortTitle
                  : config.siteTitle
                : config.siteShortTitle
                ? [config.siteShortTitle, config.siteTitle].join(' : ')
                : config.siteTitle}
            </Typography>
          </Button>

          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}

          <div className={classes.grow} />
          {/* <div className={classes.grow} /> */}
          <div className={classes.sectionDesktop}>
            {data && (
              <div className={classes.mainMenu}>
                {data.map((menu) => {
                  if (JSON.parse(menu.display)) {
                    if (JSON.parse(menu.addRoute)) {
                      return (
                        <Button
                          to={menu.route}
                          exact
                          component={NavLink}
                          activeClassName={classes.currentPage}
                          key={menu.id}
                        >
                          {menu.itemTitle}
                        </Button>
                      )
                    } else {
                      return (
                        <Button
                          {...JSON.parse(menu.contentSource)}
                          key={menu.id}
                        >
                          {menu.itemTitle}
                        </Button>
                      )
                    }
                  }
                  return null
                })}
                {archive && (
                  <>
                    <Button
                      id="archive-button"
                      aria-controls={open ? 'archive-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      Arhivă
                    </Button>
                    <Menu
                      id="archive-menu"
                      aria-labelledby="archive-button"
                      anchorEl={archAnchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      component="div"
                    >
                      {archive.map((aitem) => (
                        <MenuItem
                          href={aitem.link}
                          target="_blank"
                          rel="noreferrer"
                          component="a"
                          onClick={handleClose}
                          key={aitem.id}
                        >
                          {aitem.itemTitle}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </div>
            )}
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* {renderMenu} */}
    </StyledEngineProvider>
  )
}

export default MainNav
