import React from 'react'

import ReactMarkdown from 'react-markdown'

import moment from 'moment'
import 'moment/locale/ro'

import clsx from 'clsx'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/styles';

import {
  StyledEngineProvider,
  Grid,
  Container,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Typography,
  Slide,
  Divider,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import CloseIcon from '@material-ui/icons/Close'
import AuthorIcon from '@material-ui/icons/Person'
import OrganisationIcon from '@material-ui/icons/AccountBalance'

// import componentList from '../../core/ComponentIterator'

// const styles = theme => ({})

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    marginTop: 0,
    textTransform: 'uppercase',
  },
  dialogButton: {
    position: 'absolute',
    top: 50,
    left: 50,
  },
  customDialog: {
    '& h2:first-of-type': {
      marginTop: 0,
    },
  },
  
  blogHero: {
    width: '100%',
  },
}))

const DialogTitle = props => {
  const classes = useStyles()
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3, 3),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
}))(MuiDialogActions)

const Transition = React.forwardRef(function Transition(props, ref) {
  // console.log(props)
  return <Slide direction="up" ref={ref} {...props} />
})

function createMarkup(content) {
  return { __html: content }
}

const TopicContent = ({ dialogData, classes }) => {
  const domenii = dialogData.keywords && dialogData.keywords.split(',')
  // console.log('TopicDetails: ', dialogData)
  return (
    <StyledEngineProvider injectFirst>
      <div className="blogContent">
        <Grid container spacing={3}>
          {dialogData.icon && (
            <Grid item xs={7} sm={4} md={3} style={{ margin: '0 auto' }}>
              <Box className={classes.cardImageContainer}>
                <img
                  alt={dialogData.title}
                  src={dialogData.icon}
                  className={classes.cardImage}
                />
              </Box>
            </Grid>
          )}
          {(dialogData.author || dialogData.authorOrganisation) && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" component="div" gutterBottom>
                  <AuthorIcon
                    color="disabled"
                    style={{ verticalAlign: 'bottom' }}
                  />{' '}
                  <ReactMarkdown
                    children={dialogData.author}
                    components={{ p: 'span' }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  component="div"
                  align="right"
                  gutterBottom
                >
                  <OrganisationIcon
                    color="disabled"
                    style={{ verticalAlign: 'bottom' }}
                  />{' '}
                  <ReactMarkdown
                    children={dialogData.authorOrganisation}
                    components={{ p: 'span' }}
                  />
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            {dialogData.hero ? (
              <img
                className={classes.blogHero}
                src={`//noapteacercetatorilor.educatiepentrustiinta.ro/blog/images/${dialogData.hero}`}
                alt={dialogData.title}
              />
            ) : (
              <Divider />
            )}
          </Grid>
          {dialogData.content && (
            <Grid
              item
              xs={12}
              // dangerouslySetInnerHTML={createMarkup(dialogData.content)}
              style={{ paddingTop: 12 }}
            >
              <Typography variant="body1" component="div" gutterBottom>
                <ReactMarkdown children={dialogData.intro} />
              </Typography>
              <Typography variant="body2" component="div" gutterBottom>
                <ReactMarkdown children={dialogData.content} />
              </Typography>
            </Grid>
          )}
          {dialogData.datePublished && (
            <>
              <Grid
                item
                xs={12}
                // dangerouslySetInnerHTML={createMarkup(dialogData.content)}
                style={{ paddingTop: 8 }}
              >
                <Divider style={{ marginBottom: 8 }} />
                <Typography
                  variant="body2"
                  component="div"
                  gutterBottom
                  align="right"
                  style={{ textTransform: 'capitalize' }}
                >
                  {moment(dialogData.datePublished).format('dddd, D MMMM YYYY')}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </StyledEngineProvider>
  )
}

export default (function FullScreenDialog(props) {
  const localClasses = useStyles()
  const theme = useTheme()
  const isWidthDownMD = useMediaQuery('(max-width:599px)');
  const isWidthUpSM = useMediaQuery('(min-width:600px)');

  const {
    dialogData,
    buttonColor,
    buttonVariant,
    buttonSize,
    buttonText,
    buttonEndIcon,
    classes,
  } = props

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(false)
  }

  // const dialogData = props.data
  // const ComponentIterator = componentList[dialogData.component]

  // console.log(dialogData)

  return (
    <StyledEngineProvider injectFirst>
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onScroll={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        style={{
          flexGrow: 1,
          display: 'flex',
          // flexDirection: 'column',
          // justifyContent: `${props.justifyContent}`,
          justifyContent: `flex-end`,
        }}
      >
        <Button
          fullWidth={false}
          variant={buttonVariant ? buttonVariant : 'text'}
          color={buttonColor ? buttonColor : 'default'}
          size={buttonSize ? buttonSize : 'medium'}
          onClick={handleClickOpen}
          endIcon={buttonEndIcon}
          className={classes.actionButton}
          style={{
            // flex: '0 1 auto',
            // alignSelf: `${dialogData.alignItems}`,
            marginRight: 8,
          }}
        >
          {buttonText}
        </Button>
        <Dialog
          aria-labelledby="scroll-dialog-title"
          fullScreen={isWidthDownMD ? true : false}
          maxWidth="lg"
          fullWidth={true}
          scroll="paper"
          // hideBackdrop
          elevation={24}
          open={open}
          onClose={handleClose}
          // TransitionComponent={React.forwardRef(function Transition(props, ref) {
          //   return <Slide direction={dialogData.transition} ref={ref} {...props} />
          // })}
          TransitionComponent={Transition}
          className={localClasses.customDialog}
        >
          {isWidthDownMD ? (
            <React.Fragment>
              <AppBar className={localClasses.appBar}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    id="scroll-dialog-title"
                    className={localClasses.title}
                    style={{ marginLeft: theme.spacing(2) }}
                  >
                    {dialogData.title}
                  </Typography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <Container maxWidth="md" style={{ margin: theme.spacing(6, 0, 3) }}>
                <TopicContent dialogData={dialogData} classes={classes} />
              </Container>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
                {dialogData.title}
              </DialogTitle>
              {dialogData && (
                <DialogContent dividers>
                  <TopicContent dialogData={dialogData} classes={classes} />
                </DialogContent>
              )}
              {dialogData.hasActionButton && (
                <DialogActions>
                  {dialogData.buttonType ? (
                    <Button
                      autoFocus
                      color="inherit"
                      type={dialogData.buttonType}
                    >
                      {dialogData.buttonActionText} {'xxa'}
                    </Button>
                  ) : (
                    <Button autoFocus color="inherit" onClick={handleClose}>
                      {dialogData.buttonActionText} {'xxb'}
                    </Button>
                  )}
                </DialogActions>
              )}
            </React.Fragment>
          )}
        </Dialog>
      </div>
    </StyledEngineProvider>
  )
})
